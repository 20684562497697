@import '../../styles/core.css';

.root {
  margin: 0;
  padding: 0;
  color: var(--default-grey-100);
  font-weight: 400;
  font-family: Manrope, sans-serif;

  & :global {
    del {
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        top: 60%;
        right: 0;
        left: 0;
        display: inline-block;
        height: 1px;
        background-color: var(--default-grey-100);
        transform: translateY(-50%);
      }
    }
  }
}

.underlined {
  text-decoration: underline;
}

.strike {
  text-decoration-line: line-through;
}

.uppercase {
  text-transform: uppercase;
}

.heading {
  font-weight: 500;
  font-family: 'Playfair Display', serif;
  font-variant-numeric: lining-nums proportional-nums;
}

.heading:is(.h5, .h6) {
  font-family: Manrope, sans-serif;
}

.h1 {
  font-size: 34px;
  line-height: 40px;
}

.h2 {
  font-size: 30px;
  line-height: 36px;
}

.h3 {
  font-size: 26px;
  line-height: 32px;
}

.h4 {
  font-size: 22px;
  line-height: 28px;
}

.h5 {
  font-size: 18px;
  line-height: 26px;
}

.h6 {
  font-size: 17px;
  line-height: 26px;
}

.text100 {
  font-size: 16px;
  line-height: 26px;
}

.text100.strong {
  font-weight: 700;
  line-height: 24px;
}

:is(.text90, .text80, .text70, .text50):is(.strong) {
  font-weight: 600;
}

.text90 {
  font-size: 14px;
  line-height: 20px;
}

.text80 {
  font-size: 12px;
  line-height: 16px;
}

.text70 {
  font-size: 10px;
  line-height: 18px;
}

.text50 {
  font-size:  16px;
  line-height: 18px;
}

.priceM {
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
}

.pricePreview {
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  font-family: Manrope, sans-serif;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.priceS {
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  line-height: 28px;
}

.root.inherited {
  color: inherit;
  font-weight: inherit;
}

.root.secondary {
  color: var(--default-grey-70);
}

.root.secondary.inherited {
  color: inherit;
}

@media (--screen-xs) {
  .h1 {
    font-size: 44px;
    line-height: 52px;
  }

  .h2 {
    font-size: 34px;
    line-height: 38px;
  }

  .h3 {
    font-size: 28px;
    line-height: 34px;
  }

  .h4 {
    font-size: 22px;
    line-height: 30px;
  }

  .h5 {
    font-size: 20px;
    line-height: 26px;
  }

  .h6 {
    font-size: 18px;
    line-height: 26px;
  }

  .text100 {
    font-size: 16px;
    line-height: 22px;
  }

  .text70 {
    font-size: 10px;
    line-height: 18px;
  }

  .priceM {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
  }

  .text100.strong {
    line-height: 22px;
  }
}

@media (--screen-lg) {
  .h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .h2 {
    font-size: 40px;
    line-height: 44px;
  }

  .h3 {
    font-size: 30px;
    line-height: 36px;
  }

  .h4 {
    font-size: 24px;
    line-height: 32px;
  }

  .h5 {
    font-size: 20px;
    line-height: 28px;
  }

  .h6 {
    font-size: 18px;
    line-height: 24px;
  }

  .text100 {
    font-size: 16px;
    line-height: 24px;
  }

  .text90 {
    font-size: 15px;
    line-height: 22px;
  }

  .text80 {
    font-size: 14px;
    line-height: 20px;
  }

  .text70 {
    font-size: 12px;
    line-height: 16px;
  }

  .priceM {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }

  .pricePreview {
    font-size: 20px;
    letter-spacing: 0.2px;
  }

  .text100.strong {
    line-height: 24px;
  }
}

@media (--screen-xl) {
  .h1 {
    font-size: 56px;
    line-height: 66px;
    letter-spacing: 0;
    text-align: left;
  }

  .h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
    text-align: left;
  }

  .h3 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0;
    text-align: left;
  }

  .h4 {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
  }

  .h5 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
  }

  .h6 {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
  }

  .text80 {
    font-size: 14px;
    line-height: 20px;
  }

  .priceM {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
  }

  .priceS {
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
  }

  .pricePreview {
    font-size: 24px;
    letter-spacing: 0.24px;
  }
}

.isBreakpointsUpdate {
  @media (--screen-md) {
    .h1 {
      font-size: 44px;
      line-height: 52px;
    }

    .h2 {
      font-size: 34px;
      line-height: 38px;
    }

    .h3 {
      font-size: 28px;
      line-height: 34px;
    }

    .h4 {
      font-size: 22px;
      line-height: 30px;
    }

    .h5 {
      font-size: 20px;
      line-height: 26px;
    }

    .h6 {
      font-size: 18px;
      line-height: 26px;
    }

    .text100 {
      font-size: 16px;
      line-height: 22px;
    }

    .text70 {
      font-size: 10px;
      line-height: 18px;
    }

    .text50 {
      font-size: 16px;
      line-height: 18px;
    }

    .priceM {
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
    }

    .text100.strong {
      line-height: 22px;
    }
  }

  @media (--screen-xl) {
    .h1 {
      font-size: 50px;
      line-height: 60px;
    }

    .h2 {
      font-size: 40px;
      line-height: 44px;
    }

    .h3 {
      font-size: 30px;
      line-height: 36px;
    }

    .h4 {
      font-size: 24px;
      line-height: 32px;
    }

    .h5 {
      font-size: 20px;
      line-height: 28px;
    }

    .h6 {
      font-size: 18px;
      line-height: 24px;
    }

    .text100 {
      font-size: 16px;
      line-height: 24px;
    }

    .text90 {
      font-size: 15px;
      line-height: 22px;
    }

    .text80 {
      font-size: 14px;
      line-height: 20px;
    }

    .text70 {
      font-size: 12px;
      line-height: 16px;
    }

    .priceM {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
    }

    .pricePreview {
      font-size: 20px;
      letter-spacing: 0.2px;
    }

    .text100.strong {
      line-height: 24px;
    }
  }

  @media (--screen-3xl) {
    .h1 {
      font-size: 56px;
      line-height: 66px;
      letter-spacing: 0;
      text-align: left;
    }

    .h2 {
      font-size: 44px;
      line-height: 52px;
      letter-spacing: 0;
      text-align: left;
    }

    .h3 {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0;
      text-align: left;
    }

    .h4 {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0;
      text-align: left;
    }

    .h5 {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0;
      text-align: left;
    }

    .h6 {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0;
      text-align: left;
    }

    .text80 {
      font-size: 14px;
      line-height: 20px;
    }

    .priceM {
      font-weight: 600;
      font-size: 32px;
      line-height: 36px;
    }

    .priceS {
      font-weight: 700;
      font-style: normal;
      font-size: 24px;
      line-height: 28px;
    }

    .pricePreview {
      font-size: 24px;
      letter-spacing: 0.24px;
    }
  }
}